body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


@font-face {
  font-family: RUBSN;
  src: url('./fonts/RUBSN.eot');
  src: url('./fonts/RUBSN.eot') format('embedded-opentype'),
  url('./fonts/RUBSN.woff2') format('woff2'),
  url('./fonts/RUBSN.woff') format('woff'),
  url('./fonts/RUBSN.ttf') format('truetype'),
  url('./fonts/RUBSN.svg#RUBSN') format('svg');
  font-style: normal;
  font-weight: 400
}

.rouble {
  font-family: RUBSN;
  font-style: normal;
}

.rouble:after {
  content: 'Я';
}