body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


@font-face {
  font-family: RUBSN;
  src: url(/static/media/RUBSN.4c102fdb.eot);
  src: url(/static/media/RUBSN.4c102fdb.eot) format('embedded-opentype'),
  url(/static/media/RUBSN.0e962c7e.woff2) format('woff2'),
  url(/static/media/RUBSN.c0549cfe.woff) format('woff'),
  url(/static/media/RUBSN.79767c98.ttf) format('truetype'),
  url(/static/media/RUBSN.c276b0a1.svg#RUBSN) format('svg');
  font-style: normal;
  font-weight: 400
}

.rouble {
  font-family: RUBSN;
  font-style: normal;
}

.rouble:after {
  content: '\42F';
}
